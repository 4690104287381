import React from 'react'
import '../Page1/Bg.scss'
import '../Page5/components/Cart.css'
import styled from 'styled-components'
import img from '../../components/Navbar/logo.png'
import Tilt from 'react-parallax-tilt';
import bg from '../Page1/mainBg.jpg'
import theme from "styled-theming";
import left from './circle.jpg'
import right from './right.png'
import { motion } from 'framer-motion'
import element from './element.png'
import { Button } from '../../components/Navbar/Navbar';
import frame from './Frame.png'

export const backgroundColor = theme("theme", {
    light: "#000000",
    dark: "#E5E5E5",
  });

const Sec = styled.section`

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    position: relative;
  
//     background-image: url(${bg});
//   background-size: 100% 100%;
//   background-repeat: no-repeat;
//  background-color: #e5e5e5;


    @media only screen and (max-width: 1140px) {
      padding: 0 0 0 4rem;
      
    }
    @media only screen and (max-width: 768px) {
      
      background-size: auto 100%;
      padding: 0;
    }

`;

const Width = styled.div`
      width: 1300px;
      display: flex;

        align-items: center;
        justify-content: space-between;
      @media only screen and (max-width: 1400px) {
        width: 95%;
      }

    @media only screen and (max-width: 1140px) {
        flex-direction: column-reverse;
    }

`

const Left = styled(motion.div)`
        width: 31rem;
        height: 34rem;
        background-image: url(${left});
        background-size: 100% 100%;
        background-repeat: no-repeat;
       
     
   
        @media only screen and (max-width: 1300px) {
            width: 30rem;
            height: 28rem;
        }
       

    @media only screen and (max-width: 768px) {

        width: 22rem;
        height: 20rem;

      }
    @media only screen and (max-width: 460px) {
        width: 16rem;
        height: 15rem;
      }
`;

const Right = styled.div`
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 30rem;
        height: auto;
  
        margin-right: 2rem;
    
`;

export const T = styled.p`
color: #8a8a8a;
 font-size: 18px;
  line-height: 28px;
   font-weight: 500;
   text-align: right;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
        text-align: left;
      }
`;

export const H = styled.h2`
color: #000;
font-weight: 600;
    margin: 1rem 0 1rem 0;
    text-align: right;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.2rem;
        font-weight: bold;
        text-align: left;
      }
`;

const StyledCart = styled.div`
      display: flex;
      width: 1100px;
      justify-content: space-between;
      @media only screen and (max-width: 1300px) {
        flex-direction: column;

        width: 95%;
      }
`

const Row = styled.div`
display: flex;
`


const Input = styled.input`
border: 2px solid #000000;
width: 12rem;
height: 3rem;
font-size: 1.1rem;
cursor: pointer;
padding-left: 5px;
`
const Details = styled.div`
      margin: 2rem 0 0 0;
`

const Page2 = () => {

    return (
        <Sec id="rarity">

            <Width>

              <Right
             
              >
                  <T style={{lineHeight:'0.1'}}>New Album</T>
                    <H style={{lineHeight:'1'}}>Album Showcase <div style={{margin:'1rem 0 0 0'}}/> –</H>
                    <T>Alienum phaedrum torquatos nec eu, vis detraxit ertssa periculiser ex, nihil expetendis in mei. Meis an pericula es euripidis, hinces partem ei est. Eos ei nisl graecis, vixenss eu qui purto zril laoreet.</T>
                </Right>

                <Left
                
                     whileHover={{ scale: 1.1,boxShadow:"0 0 25px #e5e5e5"}}
                >
                  <H
                    style={{
                      position: 'absolute',
                      textAlign: 'left',
                      right:'0',
                      top:'10%',
                      lineHeight:'1.2'
                    }}
                  >New Album <br/> Release</H>
                </Left>
               

            </Width>

            <div class="vertical-line" />
            <div class="vertical-line2" />
            <div class="vertical-line3" />
            <div class="vertical-line4" />
            <div class="vertical-line5" />
            <div class="vertical-line6" />

        </Sec>
    )
}

export default Page2





