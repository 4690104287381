import React, { useEffect, useState } from 'react'
import '../Page1/Bg.scss'
import styled from 'styled-components'
import img from '../../components/Navbar/logo.png'
import Tilt from 'react-parallax-tilt';
import bg from '../Page1/mainBg.jpg'
import theme from "styled-theming";
import { H, T } from '../Page2/Page2'
import { gql, useQuery } from '@apollo/client';
import ipfs from '../../ipfs';
import RelatedProduct from '../Page5/components/RelatedProduct';
import Details from './components/Details';

export const backgroundColor = theme("theme", {
    light: "#000000",
    dark: "#E5E5E5",
});

const Sec = styled.section`
    min-height: 100vh;
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem  0 3rem 3%;
    overflow-x: hidden;
    background-color:  #ffffff;
    

    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 1rem 0 2rem 0;
        flex-direction: column;
        justify-content: space-around;
      }
`;

const Width = styled.div`
      width: 1300px;
      display: flex;
      
        margin: 0 0 0 1%;
        align-items: center;
        justify-content: space-between;
      @media only screen and (max-width: 1400px) {
        width: 95%;
        margin: 0 0 0 6%;
      }
   
    @media only screen and (max-width: 1100px) {
        flex-direction: column;
    }
    @media only screen and (max-width: 768px) {
        margin: 0 0 0 ;
      }

`

const Left = styled.div`
        margin-right: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
     
    @media only screen and (max-width: 768px) {
        margin-right: 0;
        width: 100%;
        padding: 0 1rem;
        height: auto;
      }
`;

const Right = styled.div`
      margin-left: 2rem;
      @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 0 1rem;
        height: auto;
        margin-left: 0;
      }
`;

const Head = styled.div`
    background-color: #e5e5e5;
    padding: 5rem 0;
    // display: flex;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const GET_SINGLE_NFT = gql`
   query NFT($id:String,$owner:String){
   artist(id:$id){
    id
    uri
    totalSupply
   }
   nfts(artist:$owner){
        id
        isSold
        tokenId
        owner
        price
        uri
        artist{
        id
        uri
        }
        transfers{
        hash
        id
        }
   }
   }

`


const Page5 = () => {
    const [nftFinal, setnftFinal] = useState("")

    async function getData(e) {
        // const data = items.map(async (e) => {
        let obj = {};
        obj.artist = {};
        if (e?.uri) {
            const result = await ipfs.get(`/ipfs/${e.uri}`);
            // return result[0].content;
            // const artist = await ipfs.get(`ipfs/${e.artist.uri}`);

            // console.log(artist[0].content.toString());
            console.log(result[0].content.toString());

            let data = JSON.parse(result[0].content.toString());
            console.log(data,"dattaa",e);
            obj.id = e.id;
            obj.totalSupply = e.totalSupply;
            obj.discord = data.discord;
            obj.email = data.email;
            obj.bio = data.bio;
            obj.coverImage = data.coverImage;
            obj.insta = data.insta;
            obj.name = data.name;
            obj.reddit = data.reddit;
            obj.telegram = data.telegram;
            obj.whatsapp = data.whatsapp;
            obj.profileImage = data.profileImage;
            setnftFinal(obj);
            return obj;
        }
        // });
        // const fetchedData = await Promise.all(data);
        // console.log(fetchedData);
        // setproductArray(fetchedData);

        // return fetchedData
    }


    console.log(`${window.location.pathname.split("/")[2]}-${window.location.pathname.split("/")[3]}`);

    const { data } = useQuery(GET_SINGLE_NFT, {
        variables: {
            id: `${window.location.pathname.split("/")[2]}`,
            owner: `${window.location.pathname.split("/")[2]}`
        }
    });

    console.log(data, "data")


    useEffect(() => {

        if (data) {
            getData(data.artist);
        }

        return () => {

        }
    }, [data])



    return (
        <Sec id="learn">
           
            
                {data && nftFinal && <Details data={nftFinal} />}
            <Width style={{ flexDirection: 'column' }}>
                {data && <RelatedProduct data={data.nfts} />}
            </Width>
            <div class="vertical-line" />
            <div class="vertical-line2" />
            <div class="vertical-line3" />
            <div class="vertical-line4" />
            <div class="vertical-line5" />
            <div class="vertical-line6" />
        </Sec>
    )
}

export default Page5
