import React from 'react'
import styled from 'styled-components'

const StyledTag = styled.div`
    background-color: #000000;
    color: #FFFFFF;

    text-align: center;
    padding: 0.5rem 2rem;
    font-weight: 600;
    position: absolute;
    right: 0;
    top: 0;
`

const Tag = (props) => {
  return (
    <StyledTag>
        {
            props.sale ? "Available"  : props.saleEnable ?
            "Coming Soon" : "Sold Out"
        }
    </StyledTag>
  )
}

export default Tag