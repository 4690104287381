import React from 'react';
import ReactDOM from 'react-dom';
import { UseWalletProvider } from 'use-wallet';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { Provider } from 'react-redux';
// import store from './redux/store';
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

// ----------------------------------------------------------------------

const client = new ApolloClient({
    uri: "https://api.thegraph.com/subgraphs/name/shahzeb8285/nftyardcolletoral",
    cache: new InMemoryCache(),
    fetchOptions: {
        mode: 'no-cors',
    },
})


ReactDOM.render(
    <UseWalletProvider chainId={97}
        connectors={{
            // This is how connectors get configured
        }}>
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
    </UseWalletProvider>
    , document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
