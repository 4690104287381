import React, { useEffect, useState } from 'react'
import '../Page1/Bg.scss'
import { data } from '../config/config'
import styled from 'styled-components'
import Card from '../../components/card/Card'
import theme from "styled-theming";
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from 'react-alice-carousel';
import bg from '../Page1/mainBg.jpg'
import { H, T } from '../Page2/Page2'
import ipfs from '../../ipfs'
import Web3 from 'web3';
import artistAbi from '../utils/artistAbi.json';
import { useWallet } from 'use-wallet';
export const backgroundColor = theme("theme", {
    light: "#000000",
    dark: "#E5E5E5",
});

const Sec = styled.section`
    min-height: 100vh;
    width: 100%;
    display : flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 3rem 4%;
    position: relative;
//     background-image: url(${bg});
//     background-size: 100% 100%;
//     background-repeat: no-repeat;
//    background-color: #e5e5e5;
   
   @media only screen and (max-width: 998px) {
    padding: 0 0 3rem 8%;
}
    @media only screen and (max-width: 768px) {
        min-height: auto;
        width: 100%;
        background-size: auto 100%;
        padding: 0 ;
    }
`;

const StyledH = styled(H)`
    font-size: 2.7rem;
`

const Width = styled.div`
      width: 1400px;
      display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: space-between;
      @media only screen and (max-width: 1700px) {
        width: 90%;
      }
    @media only screen and (max-width: 1400px) {
        width: 95%;
    }
    @media only screen and (max-width: 1100px) {
        flex-direction: column;
    }

`


const resposive = {
    600: {
        items: 1,
    },

    1000: {
        items: 2,
    },
    1200: {
        items: 3,
    },
    1600: {
        items: 4,
    },
    2400: {
        items: 6,
    }
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  place-items: center;
  width: 100%;
`
const TextConatiner = styled.div`
    margin: 0 0 0 2rem;
   
`

const Page3 = (props) => {
    const wallet = useWallet();
    const [productArray, setproductArray] = useState("");
    async function getData(items) {
        const data = items.map(async (e) => {
            let obj = {};
            if (e.uri) {
                const result = await ipfs.get(`/ipfs/${e.uri}`);
                // return result[0].content;
                console.log(result[0].content.toString());

                let data = JSON.parse(result[0].content.toString());
                console.log(data);
                obj.id = e.id;
                obj.isSold = e.isSold;
                obj.owner = e.owner;
                obj.price = e.price;
                obj.tokenId = e.tokenId;
                obj.image = data.image;
                obj.name = data.name;
                obj.description = data.description;
                obj.quantity = data.quantity;
                obj.artist = e.artist;
                return obj;
            }
        });
        const fetchedData = await Promise.all(data);
        console.log(fetchedData);
        setproductArray(fetchedData);

        return fetchedData
    }

    useEffect(() => {

        wallet.connect();
        console.log(props.data, "props");
        if (props.data.nfts && props.data.nfts.length > 0) {
            // setproductArray(props.data.nfts);
            getData(props.data.nfts);
        }
        // getData(["QmWAPiTt8dyjiFQQdR9uJHquUfspxqeMxewJPj9sK5Eazx", "QmNQjdgzLWTndPUJLa4ZZbUoQ9fvRBGfy4YdfgdL7H8RnJ", "QmNQjdgzLWTndPUJLa4ZZbUoQ9fvRBGfy4YdfgdL7H8RnJ", "QmNQjdgzLWTndPUJLa4ZZbUoQ9fvRBGfy4YdfgdL7H8RnJ"]);
        return () => {

        }
    }, [])


    const handleApprove = async (ownerAddress) => {
        const web3 = new Web3(Web3.givenProvider);
        let contract = await new web3.eth.Contract(artistAbi, ownerAddress)//TOKEN_IDO_ABI
        console.log(contract, wallet);

        let tx = await contract.methods.setApprovalForAll(ownerAddress, true).send({
            from: wallet.account
        })
        console.log(tx);
    }

    const handleBuy = async (ownerAddress, price, id) => {
        const web3 = new Web3(Web3.givenProvider);
        let contract = await new web3.eth.Contract(artistAbi, ownerAddress)//TOKEN_IDO_ABI
        console.log(contract, wallet);

        let tx = await contract.methods.buy(id).send({
            from: wallet.account,
            value: price
        });
        console.log(tx);
        // let tx = await contract.methods.setApprovalForAll("0x6058532b7F412A20611CFf7D776F1e1833205F4A",true).send({
        //     from:wallet.account
        // })
        // await contract.methods.flipSale().send({ from: wallet.account })
        // await contract.methods.buy(1).send({
        //     from:wallet.account,
        // value:"1000000000000000000"
        // });
        // console.log(tx);
    }

    return (
        <Sec>

            <Width>

                {/* <AliceCarousel
                autoPlay={true}
                infinite={true}
                animationDuration={1468}
                disableButtonsControls={true}
                disableDotsControls={true}
                autoPlayStrategy={'none'}
                autoHeight={true}
                paddingLeft={60}
                responsive={resposive}
              
            > */}
                <TextConatiner >
                    {/* <T style={{ textAlign: 'left', lineHeight: '0.5' }}>Madison Square Garden</T> */}
                    <StyledH style={{ textAlign: 'left' }}>Nfts<div style={{margin:'1.5rem 0 0 0',height:'0.3rem',width:'2.2rem',backgroundColor:"#000"}}/></StyledH>

                </TextConatiner>
                <Grid>


                    {productArray && productArray.length > 0 && productArray?.map((e, i) => (
                        // img={e.img} name={e.name.toUpperCase()} desc={e.desc} bg={e.bg}/
                        // <Card >
                        console.log(e.toString()),
                        // <div>

                        // </div>
                        <Card onclick={() => {
                            console.log("click");
                            // handleBuy();
                            window.location.pathname = `/nft/${e.artist.id}/${e.tokenId}`

                            // window.location.pathname = `/nft/0x169b8f16454a6e891c50b6009584b2cd6009b567`
                        }} style={{ cursor: "pointer" }} saleEnabled={e.artist.saleEnabled} owner={e.owner} isSold={e.isSold} img={e?.image || "/images/Frame.png"} name={e?.name || "test"} price={e.price / 10 ** 18} desc={e?.description || "test description"} bg={"/images/Frame.png"} />


                    ))}

                </Grid>
                {/* </AliceCarousel> */}
            </Width>
            <div class="vertical-line" />
            <div class="vertical-line2" />
            <div class="vertical-line3" />
            <div class="vertical-line4" />
            <div class="vertical-line5" />
            <div class="vertical-line6" />
        </Sec>
    )
}

export default Page3
