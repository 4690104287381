import React from 'react'
import './App.css'
import loading from './loading.gif'
import Navbar from './components/Navbar/Navbar'
import Home from './Page/Page1/Home'
import Page2 from './Page/Page2/Page2'
import Page3 from './Page/Page3/Page3'
import Page3a from './Page/Page3a/Page3a'
import Page4 from './Page/Page4/Page4'
import Page5 from './Page/Page5/Page5'
import Page6 from './Page/Page6/Page6'
import Page6a from './Page/Page6a/Page6a'
import Page6b from './Page/Page6b/Page6b'
import Page7 from './Page/Page7/Page7'
import Footer from './Page/Foot/Footer'
import Header from './components/Sidebar/Header'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client';

const GETALL_ARTIST = gql`
  query{
    artists{
      id
      uri
      totalSupply
      saleEnabled
      createdAt
    }
    nfts{
    id
    isSold
    tokenId
    owner
    price
    uri
    artist{
      id
      saleEnabled
    }
    transfers{
      hash
      id
    }
  }
}
`

const App = () => {

  const { data } = useQuery(GETALL_ARTIST);

  console.log(data, "datat");

  return (
    <  >
    
      
      <Header />
      {/* <Navbar /> */}
      {data && <Router>
        <Routes>
          <Route path='/' exact element={<div>
            <Home />
            <Page2 />
            {data && <Page3 data={data} />}
            <Footer line/>
          </div>
          }  />
          <Route path='/nft/:contract/:id' exact element={
            <div>
              <Page5 />
              <Footer />
            </div>
          } />
          <Route path='/artist/:contract' exact element={
            <div>
              <Page4 />
              <Footer line />
            </div>
          } />
            
        </Routes>

      </Router>
      
      
      }
      {/* <Page4 /> */}
      {!data && <div style={{ width: "100%" , height:'100%'}}>
        <img style={{ margin: "auto", width:"100%",height:'100%' }} src={loading} />
      </div>}
      {/* <Header />
      <Home />
      <Page2 />
      <Page3 /> */}
      {/* <Page3a />  X */}
      {/* <Page4 />
      <Page5 /> */}
      {/* <Page6 />  X
      <Page6a /> X
      <Page6b /> X
      <Page7 /> X */}
      
        

    </>
  )
}

export default App
