import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import theme from 'styled-theming';
import Tilt from 'react-parallax-tilt';
import item from './item.jpg'
import { motion } from 'framer-motion';
import { H, T } from '../../Page/Page2/Page2'
import Tag from './Tag'

export const backgroundColor = theme("theme", {
    light: "#000000",
    dark: "#E5E5E5",
});

export const bColor = theme("theme", {
    light: "linear-gradient(to right, #36d1dc, #5b86e5)",
    dark: "linear-gradient(to right, #0f2027, #203a43, #2c5364)",
});

const StyledH = styled(H)`
    font-size: 1.2rem;
    color: 	#101010;
  `

const Card = (props) => {
    const Box = styled.div`
        min-height: 20rem; 
        width: 18rem;   
        cursor:pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        // padding: 14px;
        
        // background: red;
        margin: ${props.sm ? '1rem 0' : '2rem 0 0 0'};
        //box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        &:hover{
            cursor: pointer;
        }
        @media only screen and (max-width: 768px) {
            height: 18rem; 
            width: 18rem;
            
        }
    `;
    const Upper = styled.div`
        height: 18rem;
        width: 100%;
        background: #e5e5e5;
        position: relative;
        `;

    const Lower = styled.div`
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1rem 0 0 0 ;
    `


    useEffect(() => {

        console.log(props);

        return () => {

        }
    }, [])

    const [style, setStyle] = useState('none');

    const Add = styled(motion.div)`
        background-color: #000000;
        color: #FFFFFF;
        width: 100%;
        text-align: center;
        padding: 1rem 0;
        font-weight: 600;
        position: absolute;
        bottom: 0;
        // transition:  0.5s linear !important;
        display: ${style} ;
`

    return (
        <Box onClick={() => { props?.onclick() }}>
            <Upper
                onMouseEnter={e => {
                    setStyle('block');
                }}
                onMouseLeave={e => {
                    setStyle('none')
                }}
            >
                <img src={`${props.img ? props.img : item}`} alt="" style={{ width: "100%", height: "100%" }} />  { /* {`${props.img ? props.img : "" }`}*/}
                <Add
                    initial={{ y: 20 }}
                    animate={{ y: 0 }}
                    transition={{
                        ease: "easeOut", duration: 0.3,
                        repeatType: "reverse",
                    }}
                >
                    Buy Now
                </Add>
                {props.saleEnabled && <Tag sale={!props.isSold } />}
                {!props.saleEnabled && <Tag saleEnable />}
            </Upper>
            <Lower>
                <StyledH style={{ margin: '0' }}>
                    {props.name ? props.name : 'Promo Shirt'}
                </StyledH>
                <span>
                    <T  style={{fontSize:"20px", color:"#000", margin:"auto", verticalAlign:"middle", display:"flex"}}>
                        <img src="/images/bnb.png" style={{ width: "20px", height: "20px", margin: "auto", display: "inline", marginRight:"5px" }} />

                        {props.price ? props.price : '$49.99'}
                    </T>
                </span>
            </Lower>

        </Box>
    )
}

export default Card
