import React, { useEffect, useState } from 'react'
import './Cart.css'
import styled from 'styled-components'
import Tilt from 'react-parallax-tilt';
import theme from "styled-theming";
import { Rating } from 'react-simple-star-rating'
import { H, T } from '../../Page2/Page2'
import Web3 from 'web3'
import ipfs from '../../../ipfs'
import artistAbi from '../../utils/artistAbi.json';
import { useWallet } from 'use-wallet';
import { motion } from 'framer-motion';

export const backgroundColor = theme("theme", {
    light: "#000000",
    dark: "#E5E5E5",
});

const StyledCart = styled.div`
   

      width: 1100px;
      display: flex;
      flex-direction: column;
        padding: 0 0 0 4%;
        align-items: center;
        justify-content: center;
    
  
    @media only screen and (max-width: 1200px) {
        flex-direction: column;
      
        align-items: center;
        justify-content: center;
    }
    @media only screen and (max-width: 1200px) {
        
        padding: 0;
    }

`

const CartPage = styled.div`
    display: flex;
    width: 1100px;
    justify-content: space-between;

    @media only screen and (max-width: 1280px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 99%;
 
    }
`

const Button = styled.button`
border: 2px solid #000000;
width: 12rem;
height: 3rem;
font-size: 1.1rem;
cursor: pointer;
`


const StyledH = styled(H)`
    font-size: 2.5rem;
    line-height: 1;
`



const Row = styled.div`
display: flex;
`

const Details = styled.div`
      margin: 2rem 0 0 0;
`
const Left = styled.div`
        width: 31rem;
        height: 34rem;
       
        @media only screen and (max-width: 510px) {
            width: 95vw;
            height: auto;
        }
`;

const Right = styled.div`
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 30rem;
        height: auto;
   
       
        @media only screen and (max-width: 510px) {
            width: 95vw;

        }
`;

const Head = styled.div`
            background-color: #fafafa;
            position: absolute;
            left: 4rem;
            top: 0;
            width: 99vw;
            height: 15rem;
            display: flex;
            
             
              align-items: center;
              justify-content: center;


              @media only screen and (max-width: 1100px) {
                left: 0;
              }
`
const Width = styled.div`

      width: 1100px;
    
    
  
    @media only screen and (max-width: 1100px) {
      width: 90%;
    }

`

{ /*  */ }

{/* */ }

const Cart = (props) => {

    const wallet = useWallet();
    const web3 = new Web3(Web3.givenProvider);
    const [rating, setRating] = useState(0);
    const [id, setid] = useState("");

    const [owner, setowner] = useState();

    const [details, setdetails] = useState(true);

    const [input, setinput] = useState(0);

    const [approved, setapproved] = useState(false);

    const handleRating = (rate) => {
        setRating(rate)
        // other logic
    }





    useEffect(() => {
        console.log(props.data, "dataaaaaaaaaaaaaaa");
        // let idInUrl = window.location.pathname.split("/")[3];
        // console.log(idInUrl);
        // let ownerAddress = window.location.pathname.split("/")[2];
        setowner(props.data.artist.id);
        setid(props.data.tokenId)
        if (wallet.status === "connected") { checkApprove(); }

        return () => {

        }
    }, [approved])


    const [loading, setloading] = useState(false);

    const renderButton = () => {

        // checkApprove();
        if (!props.data.isSold) {
            if (loading) {
                return (
                    <Button type='button' style={{ backgroundColor: '#fff', color: '#000' }}>
                        Loading...
                    </Button>
                )
            } else {
                if (wallet.status === "connected") {
                    checkApprove();
                    return (
                        <div>
                            {/* <Input value={input} onChange={(e) => {
                            setinput(e.target.value)
                        }} type="number" style={{}} /> */}
                            <Button onClick={() => { approved ? handleBuy() : handleApprove() }} type='button' sstyle={{ backgroundColor: '#000', color: '#FFF' }}>
                                {approved ? "Buy" : "Approve"}
                            </Button>
                        </div>
                    )
                } else {
                    // if (wallet.status === "connected" && !approved) {
                    //     return (<Button onClick={() => { handleApprove() }} type='button' style={{ backgroundColor: '#fff', color: '#000' }}>
                    //         Approve
                    //     </Button>)
                    // } else {
                    return (<Button onClick={() => { wallet.connect() }} type='button' style={{ backgroundColor: '#fff', color: '#000' }}>
                        Unlock Wallet
                    </Button>)
                    // }
                }


            }
        } else {
            return (
                <div>
                    <Button type='button' style={{ backgroundColor: '#fff', color: '#000' }}>
                        Sold Out
                    </Button>
                    <div style={{ marginTop: "10px" }}>
                        Owner : {props.data.owner}
                    </div>
                </div>
            )
        }
    }

    const checkApprove = async () => {
        if (wallet.status === "connected" && owner) {
            let contract = await new web3.eth.Contract(artistAbi, owner)//TOKEN_IDO_ABI
            console.log(contract, wallet);
            const approval = await contract.methods.isApprovedForAll(wallet.account, owner).call();
            console.log(approval);
            setapproved(approval);
        } else {
            // wallet.connect();
        }
    }


    const handleApprove = async () => {

        let contract = await new web3.eth.Contract(artistAbi, owner)//TOKEN_IDO_ABI
        console.log(contract, wallet);

        let tx = await contract.methods.setApprovalForAll(owner, true).send({
            from: wallet.account
        })
        console.log(tx);
        if (tx.status) {
            checkApprove();
        }
    }



    const handleBuy = async () => {
        setloading(true);
        const web3 = new Web3(Web3.givenProvider);
        let contract = await new web3.eth.Contract(artistAbi, owner)//TOKEN_IDO_ABI
        console.log(contract, wallet);

        let tx = await contract.methods.buy(id).send({
            from: wallet.account,
            value: props.data.price
        });
        setloading(false)
        console.log(tx);
        // let tx = await contract.methods.setApprovalForAll("0x6058532b7F412A20611CFf7D776F1e1833205F4A",true).send({
        //     from:wallet.account
        // })
        // await contract.methods.flipSale().send({ from: wallet.account })
        // await contract.methods.buy(1).send({
        //     from:wallet.account,
        // value:"1000000000000000000"
        // });
        // console.log(tx);
    }

    return (

        <StyledCart >
            {/* <Head>
                <Width>
                    <div>
                        <T style={{ textAlign: 'left' }}> {props.data.name ? props.data.name : 'Red California'} </T>
                        <StyledH style={{ textAlign: 'left' }}> Shop</StyledH>
                    </div>
                </Width>
            </Head> */}

            <CartPage >
                <Left>
                    <img src={props.data.image ? props.data.image : 'https://mixtape.qodeinteractive.com/wp-content/uploads/2016/09/shop-img-5-635x675.jpg'} alt='' style={{ width: '100%', height: '100%' }} />
                    {/*  props.data.image ? props.data.image : 'https://mixtape.qodeinteractive.com/wp-content/uploads/2016/09/shop-img-5-635x675.jpg' */}
                </Left>

                <Right>

                    <H style={{ textAlign: 'left', fontSize: "4rem" }}>{props.data.name}</H>

                    <ul style={{ listStyle: "none", display: "inline" }}>
                        <li onClick={() => {
                            setdetails(true)
                        }} style={{ display: "inline", cursor: "pointer", padding: "5px", textDecoration: `${details ? "underline" : ""}` }}>
                            Details
                        </li>
                        <li onClick={() => {
                            setdetails(false)
                        }} style={{ display: "inline", cursor: "pointer", padding: "5px", textDecoration: `${!details ? "underline" : ""}` }}>
                            History
                        </li>
                    </ul>
                    {details &&
                        <div>
                            <span>
                                <T style={{ fontSize: "2.5rem", color: "#000", verticalAlign: "middle", margin: '2rem 0 3rem 0', display: "flex" }}>
                                    <img src="/images/bnb.png" style={{ width: "30px", display: "inline", marginRight: "5px" }} />

                                    {props.data.price ? props.data.price / 10 ** 18 : '$49.99'}
                                </T>
                            </span>
                            {/* <T style={{
                        textAlign: 'left', color: '#909090',
                        fontSize: '2.5rem', margin: '2rem 0 3rem 0',
                        display: "flex",
                        verticalAlign: "middle",

                    }}> <img src="/images/bnb.png" style={{ width: "35px", height: "35px", marginRight: "5px" }} />{props.data.price / 10 ** 18}
                    </T> */}
                            <T style={{ textAlign: 'left' }}>
                                {props.data.description}
                                {/* Alienum phaedrum torquatos nec eu, vis detraxit periculis ex, nihil expetendis in mei. Mei an pericula euripidis, hinc partem ei est. Eos ei nisl graecis, vix aperiri consequat an. Eius lorem tincidunt vix at, vel pertinax sensibus id, error epicurei mea et. Mea facilisis urbanitas. */}
                            </T>

                            <Details>
                                {props.data.sku &&
                                    <Row style={{}}><T style={{ margin: '0', color: '#000', fontWeight: '600' }}>SKU</T><T style={{ margin: '0 0 0 5.3rem' }}>{props.data.sku}</T></Row>
                                }
                                {props.data.tags &&
                                    <Row style={{}}><T style={{ margin: '0', color: '#000', fontWeight: '600' }}>Tags</T><T style={{ margin: '0 0 0 4.5rem' }}>{props.data.tags}</T></Row>
                                }
                                {props.data.category &&
                                    <Row style={{}}><T style={{ margin: '0', color: '#000', fontWeight: '600' }}>Category</T><T style={{ margin: '0 0 0 2rem' }}>{props.data.category}</T></Row>
                                }
                            </Details>
                            <Row style={{ margin: '2rem 0' }}>
                                {props.data.artist.saleEnabled && renderButton()}

                            </Row>
                        </div>
                    }
                    {
                        !details && <div style={{marginTop:"1.5rem"}}>
                            {props.data.transfers && props.data.transfers.length > 0 && props.data.transfers.map((e) => {
                                return (

                                    <div style={{ borderRadius: "10px", boxShadow: "0 0 6px #c0c0c0", padding: "8px", margin: "4px", boxSizing: "border-box", overflowWrap: "break-word", textAlign: "left" }}>
                                        <span style={{display:"flex"}}>
                                        <T style={{ color: '#000' }}>Transfer By :</T> <T style={{ color: '#000', fontWeight: '600', paddingLeft:"3px" }}>{e.to.slice(0,8)}.....{e.to.slice(-8)}</T>
                                        </span>
                                        <span style={{display:"flex"}}>
                                           <T>Transfer From :</T> <T style={{paddingLeft:"3px"}} >{e.from.slice(0,8)}.....{e.from.slice(-8)}</T>
                                        </span>
                                    </div>

                                )
                            })}

                        </div>
                    }
                </Right>
            </CartPage>

        </StyledCart>


    )
}

export default Cart;
