import React,{ useState, useEffect} from 'react'
import styled from 'styled-components'
import './Bg.scss'
 import bg from './mainBg.jpg'
import { motion } from 'framer-motion'
import { useSelector, useDispatch } from "react-redux";
import { BsBoxArrowUp } from 'react-icons/bs';
// import { Link, animateScroll as scroll } from "react-scroll";
import Head from './head.png'
import Tilt from 'react-parallax-tilt'
import SplitPane, { Pane } from 'react-split-pane';

const HomePage = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  min-height: 100vh;
  width: 100%;
  position: relative;
  padding: 0 0 0 4%;
  // background-image: url(${bg});
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
//  background-color: #e5e5e5;

@media only screen and (max-width: 1024px){
  padding: 0 0 0 5%;
}

@media only screen and (max-width: 900px){
  padding: 0 0 0 6rem;
}

@media only screen and (max-width: 768px) {
    
    width: 100%;
    padding: 0 0 0 ;
    
    flex-direction: column;
}
@media only screen and (max-width: 640px) {
  
}
@media only screen and (max-width: 510px) {
 
  width: 100%;
 
  
  flex-direction: column;
}

`
const H = styled.p`
  position: absolute;
  color: #000000;
  font-size: 3.5rem;
  font-weight: 400;
  @media only screen and (max-width: 1100px) {
 
    display: none;
  }
`

const Center = styled(Tilt)`
        width: 66rem;
        height: 52rem;
        z-index: 2;
        position: relative;
        border-radius: 1rem;
        opacity: 1 !important;
        @media only screen and (max-width: 1300px) {
      
          width: 57rem;
          height: 45rem;
      }
      @media only screen and (max-width: 1100px) {
 
        display: none;
      }
      
`;

const SmallCenter = styled(Tilt)`
        display: none;
        
      @media only screen and (max-width: 1100px) {
        display: block;
        width: 30rem;
        height: 38rem;
        z-index: 2;
      }
      @media only screen and (max-width: 640px) {
        display: block;
        width: 25rem;
        height: 33rem;
        z-index: 2;
      }
      @media only screen and (max-width: 510px) {
        display: block;
        width: 18rem;
        height: 28rem;
        z-index: 2;
      }
      
`;

const ShowButton = styled.div`
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    background-color: #505050; // 
    color: #FFF ;
    font-weight: bold;
    cursor: pointer;  {/* <img src={Head} alt='' style={{width:'100%',height:'100%'}} />
    bottom: 10rem;
    display: grid;
    place-items: center;
    z-index: 7;
    @media only screen and (max-width: 768px) {
      right: 2rem ;
    bottom: 5rem;
    }
    
`

const Home = () => {

    // const darkThemeEnabled = useSelector((state) => state.preferences.darkThemeEnabled);
    // const dispatch = useDispatch();
    
    // const [ showButton, setShowButton ] = useState(false)

    // const showButtonTop = () => {
    //   // console.log(window.scrollY)
    //   if (window.scrollY >= 600) {
    //     setShowButton(true)
    //   } else {
    //     setShowButton(false)
    //   }
    // }
  
    // useEffect(() => {
    //   showButtonTop()
    //   // adding the event when scroll change background
    //   window.addEventListener("scroll", showButtonTop)
    // },[])  

const Styleddd = styled.g`
@media only screen and (max-width: 734px) {
  
    display: none;
  
}

`

    return (
      <section className="all">
      <div class="arrow arrow--top">
      <svg style={{zIndex:'-2'}} xmlns="http://www.w3.org/2000/svg" width="270.11" height="649.9" overflow="visible">
     
      <Styleddd  className="item-to bounce-1">
        <path class="geo-arrow draw-in" d="M135.06 142.564L267.995 275.5 135.06 408.434 2.125 275.499z" />
      </Styleddd>
      <circle className="geo-arrow item-to bounce-2" cx="194.65" cy="69.54" r="7.96" />
      <circle className="geo-arrow draw-in" cx="194.65" cy="39.5" r="7.96" />
      <circle className="geo-arrow item-to bounce-3" cx="194.65" cy="9.46" r="7.96" />
      <g className="geo-arrow item-to bounce-2">
        <path className="st0 draw-in" d="M181.21 619.5l13.27 27 13.27-27zM194.48 644.5v-552" />
      </g>
      </svg>
      </div>
      <div className="arrow arrow--bottom">
      <svg xmlns="http://www.w3.org/2000/svg" width="31.35" height="649.9" overflow="visible">
      <style>
       
      </style>
      <g className="item-to bounce-1">
        <circle className="geo-arrow item-to bounce-3" cx="15.5" cy="580.36" r="7.96" />
        <circle className="geo-arrow draw-in" cx="15.5" cy="610.4" r="7.96" />
        <circle className="geo-arrow item-to bounce-2" cx="15.5" cy="640.44" r="7.96" />
        <g className="item-to bounce-2">
          <path className="geo-arrow draw-in" d="M28.94 30.4l-13.26-27-13.27 27zM15.68 5.4v552" />
        </g>
      </g>
      </svg>
      </div>
      <div className="main">
      <div className="main__text-wrapper">
      <HomePage id="home">

      {/* <video autoPlay loop muted className="video">
        <source src={videobg} type="video/mp4"/>
      </video>
      */}




      <motion.div
        initial={{ x: 0, y:0}}
        animate={{ x: 0, y: -20 }}
        transition={{ duration: 1, repeat: Infinity ,repeatType: "reverse",}}
      >
        <Center>
        <H style={{left:'0',top:'15%'}}>
            Chanse Co
          </H>
          <H
            style={{right:'0',bottom:'20%'}}
          >
          New Album
          </H>
          <img src={Head} alt='' style={{width:'60%',height:'100%',zIndex:'2'}} />
        </Center>
      </motion.div>

      <motion.div
        initial={{ x: 0, y:0}}
        animate={{ x: 0, y: -20 }}
        transition={{ duration: 1, repeat: Infinity ,repeatType: "reverse",}}
      >
        <SmallCenter>
        
          <img src={Head} alt='' style={{width:'100%',height:'100%',zIndex:'2'}} />
        </SmallCenter>
      </motion.div>

        {/* <ModeButton
            onChange={() => dispatch({ type: TOGGLE_DARKTHEME })}
            checked={darkThemeEnabled}
            size={80}
        /> */}

      {/* { showButton ? 

        <ShowButton
          type="button"
          to="home" spy={true} smooth={true}
        >
          <BsBoxArrowUp size="2rem"/>
        </ShowButton> :
        
        null
      } */}
       

      </HomePage>     
      <svg style={{zIndex:'-1'}} xmlns="http://www.w3.org/2000/svg" className="dotted-circle" width="352" height="352" overflow="visible">
        <circle cx="176" cy="176" r="174" fill="none" stroke="#fff" stroke-width="2" stroke-miterlimit="10" stroke-dasharray="12.921,11.9271"/>
      </svg>
      </div>
      </div>
      <div class="vertical-line" />
        <div class="vertical-line2" />
        <div class="vertical-line3" />
        <div class="vertical-line4" />
        <div class="vertical-line5" />
        <div class="vertical-line6" />
  </section>
       
    )
}

export default Home

