import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
// import { Link, animateScroll as scroll } from "react-scroll";
import { H, T } from '../../Page2/Page2'
import { BsCalendar2Check } from 'react-icons/bs'
import { smalldata } from '../../config/config'
import Card from '../../../components/card/Card'
import Web3 from 'web3'
import ipfs from '../../../ipfs'
import artistAbi from '../../utils/artistAbi.json';
import { useWallet } from 'use-wallet';


const StyledRelatedProduct = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0 0 0;
    width: 100%;
    @media only screen and (max-width: 768px) {
        padding: 0 0 0 ;
    }
`
const Width = styled.div`
      width: 1300px;
      display: flex;
        
        // align-items: center;
        flex-direction: column;
        justify-content: space-between;
      @media only screen and (max-width: 1400px) {
        width: 95%;
      }
   
    @media only screen and (max-width: 1100px) {
        flex-direction: column;
    }

`
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  place-items: center;
  width: 100%;
`

const RelatedProduct = (props) => {
    const wallet = useWallet();
    const [productArray, setproductArray] = useState("");
    async function getData(items) {
        const data = items.map(async (e) => {
            let obj = {};
            if (e.uri) {
                const result = await ipfs.get(`/ipfs/${e.uri}`);
                // return result[0].content;
                console.log(result[0].content.toString());

                let data = JSON.parse(result[0].content.toString());
                console.log(data);
                obj.id = e.id;
                obj.isSold = e.isSold;
                obj.owner = e.owner;
                obj.price = e.price;
                obj.tokenId = e.tokenId;
                obj.image = data.image;
                obj.name = data.name;
                obj.description = data.description;
                obj.quantity = data.quantity;
                obj.artist = e.artist;
                return obj;
            }
        });
        const fetchedData = await Promise.all(data);
        console.log(fetchedData);
        setproductArray(fetchedData);

        return fetchedData
    }

    useEffect(() => {

        wallet.connect();
        // setproductArray(props.data);
        getData(props.data);
        // getData(["QmWAPiTt8dyjiFQQdR9uJHquUfspxqeMxewJPj9sK5Eazx", "QmNQjdgzLWTndPUJLa4ZZbUoQ9fvRBGfy4YdfgdL7H8RnJ", "QmNQjdgzLWTndPUJLa4ZZbUoQ9fvRBGfy4YdfgdL7H8RnJ", "QmNQjdgzLWTndPUJLa4ZZbUoQ9fvRBGfy4YdfgdL7H8RnJ"]);
        return () => {

        }
    }, [])


    const handleApprove = async () => {
        const web3 = new Web3(Web3.givenProvider);
        let contract = await new web3.eth.Contract(artistAbi, "0x6058532b7F412A20611CFf7D776F1e1833205F4A")//TOKEN_IDO_ABI
        console.log(contract, wallet);

        let tx = await contract.methods.setApprovalForAll("0x6058532b7F412A20611CFf7D776F1e1833205F4A", true).send({
            from: wallet.account
        })
        console.log(tx);
    }

    const handleBuy = async () => {
        const web3 = new Web3(Web3.givenProvider);
        let contract = await new web3.eth.Contract(artistAbi, "0x6058532b7F412A20611CFf7D776F1e1833205F4A")//TOKEN_IDO_ABI
        console.log(contract, wallet);

        let tx = await contract.methods.buy(1).send({
            from: wallet.account,
            value: "1000000000000000000"
        });
        console.log(tx);
        // let tx = await contract.methods.setApprovalForAll("0x6058532b7F412A20611CFf7D776F1e1833205F4A",true).send({
        //     from:wallet.account
        // })
        // await contract.methods.flipSale().send({ from: wallet.account })
        // await contract.methods.buy(1).send({
        //     from:wallet.account,
        // value:"1000000000000000000"
        // });
        // console.log(tx);
    }

    return (
        <StyledRelatedProduct>
            <Width>
                <H style={{ textAlign: 'left' }}>Related NFTs</H>
                <Grid>


                    {productArray && productArray.length > 0 && productArray?.map((e, i) => (
                        // img={e.img} name={e.name.toUpperCase()} desc={e.desc} bg={e.bg}/
                        // <Card >
                        console.log(e.toString()),
                        // <div>

                        // </div>
                        <Card onclick={() => {
                            console.log("click");
                            // handleBuy();
                            window.location.pathname = `/nft/${e.artist.id}/${e.tokenId}`

                            // window.location.pathname = `/nft/0x169b8f16454a6e891c50b6009584b2cd6009b567`
                        }} style={{ cursor: "pointer" }} saleEnabled={e.artist.saleEnabled} owner={e.owner}  isSold={e.isSold} img={e?.image || "/images/Frame.png"} name={e?.name || "test"} price={e.price / 10 ** 18} desc={e?.description || "test description"} bg={"/images/Frame.png"} />


                    ))}

                    {!productArray && <div style={{ width: "100%", height: '100%' }}>
                        <img style={{ margin: "auto", width: "100%", height: '100%' }} src={"/images/loading.gif"} />
                    </div>}

                </Grid>
            </Width>
        </StyledRelatedProduct>
    )
}

export default RelatedProduct
