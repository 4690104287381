import React, { useState } from "react";
//import react pro sidebar components
import './Header.css'
import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
  } from "react-pro-sidebar";
  
  //import icons from react icons
  import { GiHamburgerMenu } from 'react-icons/gi';
  import { ImCross } from 'react-icons/im';
  import { ImInstagram } from 'react-icons/im';
  import { ImTwitter } from 'react-icons/im';
  import { GrFacebook } from 'react-icons/gr';
  
  //import sidebar css from react-pro-sidebar module and our custom css 
  import "react-pro-sidebar/dist/css/styles.css";
  import "./Header.css";
import styled from "styled-components";
  
  const StyledMenu = styled(SidebarFooter)`
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: space-around;
    height: 8rem;
    margin: 0 0 1.5rem 0;
    position: absolute: 
    bottom: 0;
    color: 	#707070;
    width: 5rem;
  `

  const Header = () => {
    
      //create initial menuCollapse state using useState hook
      const [menuCollapse, setMenuCollapse] = useState(true)
  
      //create a custom function that will change menucollapse state from false to true and true to false
    const menuIconClick = () => {
      //condition checking to change state from true to false and vice versa
      menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };
  
    return (
      <>
        <div id="header">
            {/* collapsed props to change menu size using menucollapse state */}
          <ProSidebar collapsed={menuCollapse} className={menuCollapse? null : "styledSidebar"}>
            <SidebarHeader>
            <div className="logotext">
                {/* small and big change using menucollapse state */}
                <p>{menuCollapse ? "m." : "M."}</p>
            </div>
            {/* <div className= {menuCollapse?"closemenu" : "openmenu"} onClick={menuIconClick}>
                {menuCollapse ? (
                  <GiHamburgerMenu color="#303030" style={{width:'2rem'}}/>
                ) : (
                  <ImCross color="#303030" style={{width:'2rem'}}/>
                )}
            </div> */}
            </SidebarHeader>
            <SidebarContent
                // style={{margin:'25vh 0 0 0'}}
            >
              {/* <Menu iconShape="square">
                <MenuItem active={true} icon={<FiHome />}>
                  Home
                </MenuItem>
                <MenuItem icon={<FaList />}>Category</MenuItem>
                <MenuItem icon={<FaRegHeart />}>Favourite</MenuItem>
                <MenuItem icon={<RiPencilLine />}>Author</MenuItem>
                <MenuItem icon={<BiCog />}>Settings</MenuItem>
              </Menu> */}
            </SidebarContent>
            <StyledMenu>
             
                <GrFacebook style={{fontSize:'1.5rem'}} className='icon'/>
                <ImInstagram  style={{fontSize:'1.5rem'}} className='icon'/>
                <ImTwitter style={{fontSize:'1.5rem'}} className='icon'/>
         
            </StyledMenu>
          </ProSidebar>
        </div>
      </>
    );
  };
  
  export default Header;